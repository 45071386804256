<template>
    <div class="main">
        <div class=" d-flex justify-space-between pa-3">
            <h2  class="text-h6" >Projects </h2>
            <v-btn class="lowercase" color="blue-accent-2" theme="dark" elevation="0" @click="() => dialog = true">
                <h4> <v-icon class="mr-2">mdi-plus</v-icon> Create New</h4>
            </v-btn>
        </div>
        <v-divider></v-divider>
    </div>
    <v-container class="pa-5">
        <v-card elevation="0" clas="mt-5">
            <v-card-text class="mt-5">
                <v-table>
                    <thead>
                        <tr>
                            <th class="text-left font-weight-black">
                                Project Name
                            </th>
                            <th class="text-left font-weight-black">
                                Creation Date
                            </th>
                            <th class="text-left font-weight-black">
                                Submit URL
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in sortedProjects" :key="item.name">
                            <td>
                                <router-link class="text-blue" :to="{
                                    name: 'projectHome',
                                    params: {
                                        project_id: item.id
                                    }
                                }">
                                    {{ item.project_name }}
                                </router-link>
                            </td>
                            <td>{{ new Date(item.created_at).toLocaleDateString('en-US') }}</td>
                            <td>https://api.jsfeedback.com/feedback/new/{{ item.id }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog">
            <v-form v-model="valid">
                <v-card class="modal">
                    <h2 class="mt-5 ml-5">Create Project</h2>
                    <v-card-text>
                        <v-text-field label="" placeholder="Enter your project Name" v-model="projectname"
                            :rules="[rules.required]"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="">
                        <v-btn :disabled="!valid" :loading="loading" class="mr-3 lowercase" color="#3F51B5"
                            variant="flat" @click="createProject()">
                            <h4>Create</h4>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
import { useDisplay } from "vuetify";
import { useAuth } from "@/store/auth.js";
import axios from "axios";

export default {
    setup() {
        const { display } = useDisplay();
        const store = useAuth();

        return { store }
    },

    components: {
    },

    data() {
        return {
            dialog: false,
            user_id: '',
            projects: '',
            projectname: '',
            loading: false,
            valid: false,
            rules: {
                required: (value) => !!value || "This field is required",
            },
        };
    },
    computed: {
        sortedProjects() {
            // Sort by `created_at` in descending order
            return [...this.projects].sort((a, b) =>
                new Date(b.created_at) - new Date(a.created_at)
            );
        },
        project_url(project_id){
            return 'localhost//'+project_id
        }
    },
    methods: {
        getProjects() {
            axios
                .get(`/api/project/getall/${this.user_id}`)
                .then((response) => {
                    if (response.status == 200) {
                        this.projects = response.data
                    }
                })
                .catch((err) => { });

        },
        createProject() {
            const fd = {
                user_id: this.user_id,
                project_name: this.projectname,
                plan_type: 'free'
            }
            axios
                .post(`/api/project/add`, fd)
                .then((response) => {
                    if (response.status == 200) {
                        this.getProjects()
                        this.loading = false
                        this.dialog = false
                        this.projectname = ''
                    }
                })


        }
    },
    created() {
        this.user_id = this.store.getUserId
        this.getProjects()
    }
};
</script>

<style scoped>
.modal {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.lowercase {
    text-transform: none;
}
</style>