import { createRouter, createWebHistory } from 'vue-router'

//DEMO
import Demo from '../views/Demo.vue'

//APP
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
//ADMIN
import ApiKey from '../views/ApiKey.vue' // Dashboard
import Dashboard from '../views/Dashboard.vue' // Dashboard
import Project from '../views/ProjectHome.vue' // Project Home


const routes = [
  //DEMO
  {
    path: '/demo',
    name: 'demo',
    component: Demo,
  },
  // APP
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '',
    name: 'home',
    component: Home,

  },
  // ADMIN
  {
    path: '/apikey',
    name: 'apikey',
    component: ApiKey,
    meta: {
      requiresAuth: true,
      sidebar:true
     },

  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      sidebar:true
     },

  },
  {
    path: '/projects/:project_id',
    name: 'projectHome',
    component: Project,
    meta: {
      requiresAuth: true,
      sidebar:true
     },

  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router