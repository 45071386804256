<template>
    <div class="main">
        <div class=" d-flex justify-space-between pa-3">
            <h2 class="text-h6" >API Key </h2>
        </div>
        <v-divider></v-divider>
    </div>
    <v-container class="pa-5">
        <v-card elevation="1" clas="mt-5">
            <h4 class=" text-subtitle-1 ma-2">API Key : </h4>
            <h4 class="ma-2">{{ apiKey }}</h4>
        </v-card>
    </v-container>
</template>

<script>
import { useDisplay } from "vuetify";
import { useAuth } from "@/store/auth.js";
import axios from "axios";

export default {
    setup() {
        const { display } = useDisplay();
        const store = useAuth();

        return { store }
    },

    components: {
    },

    data() {
        return {
            user_id: '',
            apiKey:'',
        };
    },
    computed: {
    },
    methods: {        
        getApiKey() {
            axios
                .post(`/api/user/apikey`,{user_id:this.user_id})
                .then((response) => {
                    if (response.status == 200) {
                        this.apiKey = response.data.apiKey
                    }
                })
                .catch((err) => { });

        },
    },
    created() {
        this.user_id = this.store.getUserId
        this.getApiKey()
    }
};
</script>

<style scoped>

.lowercase {
    text-transform: none;
}
</style>