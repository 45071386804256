<template>
    <Header />
    <v-container class=" d-flex mt-5 ">
        <v-row>
            <v-col class="" :cols="$vuetify.display.smAndUp ? '6' : '12'">
                <v-sheet class="d-flex align-start flex-column mb-5" height="">
                    <v-sheet class="ma-2 pa-2">
                        <h2 class="text-h3 font-weight-bold text-left"> Feedback Collection with
                            <span class="text-blue-darken-4 "> AI-Powered </span> Modal Plugin
                        </h2>
                        <h3 class=" font-weight-light text-left mt-3"> Seamlessly capture, analyze, and organize users
                            feedback</h3>
                    </v-sheet>
                    <v-sheet class="ma-2 pa-2">
                        <router-link class="text-decoration-none text-black" to="signup">
                            <v-btn class="lowercase font-weight-bold mt-5" size="large" color="primary">
                                Get Started
                            </v-btn>
                        </router-link>
                    </v-sheet>

                </v-sheet>
            </v-col>
            <v-col :cols="$vuetify.display.smAndUp ? '6' : '12'">
                <v-card class="mt-2" elevation="1" variant="outlined">
                    <div class="d-flex justify-space-between ma-2">
                        <v-card-title class="d-flex">
                            Demo Project
                        </v-card-title>
                        <div class="d-flex justify-center">
                            <v-btn class="text-capitalize" size="large" variant="outlined" color="blue-darken-4"
                                @click="this.openFeedbackModal()">
                                Send Feedback
                            </v-btn>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-card-text class="mt-2">
                        <v-table density="compact" height="200px">
                            <thead>
                                <tr>
                                    <th class="text-left font-weight-black">
                                        Feedback
                                    </th>
                                    <th class="text-left font-weight-black">

                                    </th>
                                    <th class="text-left font-weight-black">
                                        Sentiment
                                    </th>
                                    <th class="text-left font-weight-black">
                                        Email
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr v-for="item in feedbacks" :key="item.id">
                                    <td>
                                        <v-chip class="mr-2" :color="getChipColor(item.feedback_type)" size="small"
                                            dark>
                                            {{ item.feedback_type }}
                                        </v-chip>
                                    </td>
                                    <td>
                                        {{ item.text }}
                                    </td>
                                    <td>
                                        <v-icon :color="getSentimentColor(item.sentiment)"
                                            :icon="getSentimentIcon(item.sentiment)"></v-icon>
                                    </td>
                                    <td>

                                    </td>
                                    <td>{{ item.email }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container class=" docs mb-5">
        <div class="d-flex justify-center mt-5 mb-5">
            <h2 class="text-h4 font-weight-bold mb-5 mt-5">
                <v-icon class="mb-5" icon="mdi-creation-outline" size="x-small" color="blue-darken-4"></v-icon>
                Give it a try <span class="text-subtitle-1">(it's free)</span>
            </h2>
        </div>
        <v-row class="d-flex justify-center" >
            <v-col :cols="$vuetify.display.smAndUp ? '8' : '12'">
                <p class="mb-3">
                    <v-icon color="blue" icon="mdi-information-outline"></v-icon>
                    Check our source code on
                    <a class="text-decoration-none text-blue font-weight-bold"
                        href="https://github.com/beyassine/jsfeedback" target='_blank'>
                        Github<v-icon icon="mdi-arrow-top-right-thin" size="x-small"></v-icon>
                    </a>
                </p>
                <div class="mt-5">
                    <h2 class="text-h6 font-weight-bold text-blue-darken-4 mb-2">
                        <v-icon class="mb-1" icon="mdi-numeric-1-circle-outline"></v-icon> Installation
                    </h2>
                    <p class="">Install the plugin via npm</p>
                    <pre><code class="language-bash">npm install jsfeedback</code></pre>
                    <p class="">Import it into your project</p>
                    <pre><code class="language-js">import FeedbackModal from 'jsfeedback';</code></pre>
                </div>
                <div class="mt-5">
                    <h2 class="text-h6 font-weight-bold text-blue-darken-4 mb-2">
                        <v-icon class="mb-1" icon="mdi-numeric-2-circle-outline"></v-icon> API Key
                    </h2>
                    <p class="">Create your account and get your API Key from
                        <router-link class="text-decoration-none text-blue-darken-4 font-weight-bold" :to="{
                            name: 'dashboard',
                        }">Dashboard
                        </router-link>
                    </p>
                </div>
                <div class="mt-5">
                    <h2 class="text-h6 font-weight-bold text-blue-darken-4 mb-2">
                        <v-icon class="mb-1" icon="mdi-numeric-3-circle-outline"></v-icon> Usage
                    </h2>
                    <p class="">Basic (VanillaJS) Example</p>
                    <pre>
        <code class="language-js">{{ jsCode }}</code>
    </pre>
                    <p class="">ReactJS Example</p>
                    <pre>
        <code class="language-jsx" v-html="preactCode"></code>
    </pre>
                </div>
                <div class="mt-5">
                    <h2 class="text-h6 font-weight-bold text-blue-darken-4 mb-2">
                        <v-icon class="mb-1" icon="mdi-numeric-4-circle-outline"></v-icon>Options
                    </h2>
                    <v-card class="table-block" elevation="2">
                        <table>
                            <thead>
                                <tr>
                                    <th>Option</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Required</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>submitUrl</td>
                                    <td>string</td>
                                    <td>URL where the feedback will be submitted.</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>apiKey</td>
                                    <td>string</td>
                                    <td>API key for authentication.</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>title</td>
                                    <td>string</td>
                                    <td>Title of the feedback modal.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>description</td>
                                    <td>string</td>
                                    <td>Description of the feedback modal.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>onSuccess</td>
                                    <td>function</td>
                                    <td>Callback invoked on successful form submission.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>onError</td>
                                    <td>function</td>
                                    <td>Callback invoked if the form submission fails.</td>
                                    <td>No</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card>
                </div>
                <div class="mt-5">
                    <h2 class="text-h6 font-weight-bold text-blue-darken-4 mb-2">
                        <v-icon class="mb-1" icon="mdi-numeric-5-circle-outline"></v-icon> Methods
                    </h2>
                    <v-card class="table-block" elevation="2">
                        <table>
                            <tbody>
                                <tr>
                                    <td>open()</td>
                                    <td>Opens the feedback modal</td>
                                </tr>
                                <tr>
                                    <td>close()</td>
                                    <td>Closes the feedback modal</td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card>
                </div>
            </v-col>            
        </v-row>
        <div class="d-flex justify-center mt-5 mb-5">
            <p class="text-p font-weight-bold mb-5 mt-5">
                <v-icon class="mb-5" icon="mdi-creation-outline" size="x-small" color="blue-darken-4"></v-icon>
                Happy Coding</p>
        </div>
    </v-container>
</template>

<script>
import Header from '../components/Header.vue'
import { useDisplay } from "vuetify";

//Feedback Modal
import FeedbackModal from 'jsfeedback';

export default {
    setup() {
        const { display } = useDisplay();
    },

    components: {
        Header,
    },

    data() {
        return {
            feedbacks: [],
            demoApiKey:'',
            jsCode: `
import FeedbackModal from 'jsfeedback';

const feedbackModal = new FeedbackModal({
    submitUrl: 'your-project-submitUrl',
    apiKey: 'your-api-key',
    title: 'We Value Your Feedback!',
    description: 'Please let us know how we can improve.',
    onSuccess: (response) => console.log('Successful!',resonse),
    onError: (error) => console.log('Failed.',error),
});

// Open the modal on button click
document.getElementById('feedback-button').addEventListener('click', () => {
    feedbackModal.open();
});`,
            reactCode: `
import React from 'react';
import FeedbackModal from 'jsfeedback';

const App = () => {
    const feedbackModal = React.useRef(null);

    React.useEffect(() => {
        feedbackModal.current = new FeedbackModal({
            submitUrl: 'https://example.com/api/feedback',
            apiKey: 'your-api-key',
            title: 'We Value Your Feedback!',
            description: 'Please let us know how we can improve.',
            onSuccess: (response) => console.log('Successful!',resonse),
            onError: (error) => console.log('Failed.',error),
        });
    }, []);

    const openModal = () => {
        feedbackModal.current.open();
    };

    return (
        <div>
            <button onClick={openModal}>Give Feedback</button>
        </div>
    );
};

export default App;`

        };
    },

    methods: {
        getSentimentIcon(sentiment) {
            switch (sentiment) {
                case "Positive":
                    return "mdi-emoticon-happy";
                case "Negative":
                    return "mdi-emoticon-sad";
                case "Neutral":
                    return "mdi-emoticon-neutral";
                default:
                    return "mdi-help-circle"; // fallback icon
            }
        },
        getSentimentColor(sentiment) {
            switch (sentiment) {
                case "Positive":
                    return "green";
                case "Negative":
                    return "red";
                case "Neutral":
                    return "grey";
                default:
                    return "grey"; // fallback color
            }
        },
        getChipColor(feedbackType) {
            switch (feedbackType) {
                case "Bug Report":
                    return "red";
                case "Feature Request":
                    return "blue";
                case "General Praise":
                    return "green";
                case "Complaint":
                    return "orange";
                case "Other":
                    return "grey";
                default:
                    return "primary"; // fallback color
            }
        },

        openFeedbackModal() {
            const modal = new FeedbackModal({
                submitUrl: 'https://api.jsfeedback.com/feedback/new/aBkBFbiK',
                apiKey:this.demoApiKey,
                title: 'Demo Project Feedback',
                description: 'Please let us know how we can improve your experience',
                onSuccess: (data) => {
                    this.feedbacks.push(data)
                },
                onError: (error) => console.log('Error Occured', error),
            });
            modal.open()
        },
    },
    computed: {
        preactCode() {
            return Prism.highlight(this.reactCode, Prism.languages.jsx, "jsx")

        }
    },
    mounted() {
        this.demoApiKey=process.env.VUE_APP_APIKEY
        Prism.highlightAll();
    },
};
</script>
<style>
.container {
    padding: 10px;
}

.docs {
    width: 100%;
}


/* Table Block Specific */
.table-block table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.table-block th,
.table-block td {
    border: 1px solid #444444;
    /* Matches Prism's subtle line colors */
    padding: 8px 12px;
}

.table-block th {
    background-color: #2d2d2d;
    /* Slightly darker for headers */
    color: #dcdcdc;
    font-weight: bold;
}

.table-block td {
    background-color: #1e1e1e;
    /* Matches Prism Tomorrow background */
    color: #dcdcdc;
}

.table-block tr:nth-child(even) td {
    background-color: #2a2a2a;
    /* Alternate row color */
}
</style>