<template>
    <v-container>
        <v-img class="center" width="150" aspect-ratio="16/9" :src=logo></v-img>
        <v-row class="d-flex justify-center">
            <v-col :cols="$vuetify.display.smAndUp ? '6' : '12'">
                <v-form v-model="valid">
                    <h2 class="mt-2">Sign In</h2>
                    <v-divider />
                    <v-row class="d-flex mt-2">
                        <v-col cols="12">
                            <h3 class="mb-3 ">Email address</h3>
                            <v-text-field :rules="emailRules" v-model="email" placeholder="Enter your email address"
                                required></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="mb-3">Password</h3>
                            <v-text-field placeholder="Enter password"
                                :append-inner-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible1 ? 'text' : 'password'" outlined v-model="password1" :rules="Required"
                                @click:append-inner="visible1 = !visible1"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn :disabled="!valid" :loading="loading" class="mt-5 text-white" color="indigo" size="large"
                        elevation="0" block dark @click="login">
                        <h3>Sign In</h3>
                    </v-btn>
                    <h5 v-if="incorrectAuth" class=" text-red mt-2">
                        Incorrect Email Or Password
                    </h5>
                    <v-divider class="mt-2 mb-2" />
                    <div class="d-flex justify-center">
                        <router-link class="text-decoration-none" :to="{
                            name: 'signup',
                        }">
                            <v-btn variant="text">
                                <h4 class=" text-black text-decoration-underline">
                                    Create Account
                                </h4>
                            </v-btn>
                        </router-link>
                    </div>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import logo from "../assets/logo.png";
import { useAuth } from "@/store/auth.js";
import { useDisplay } from "vuetify";

export default {
    name: 'Admin',

    setup() {
        const { display } = useDisplay();
        const store = useAuth();

        return { store }
    },

    components: {


    },

    data() {
        return {
            logo: logo,
            valid: false,
            fullname: '',
            email: '',
            password1: '',
            visible1: false,
            visible2: false,
            Required: [
                (v) => !!v || "This field is required",
            ],
            emailRules: [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Incorrect Email Adress'
            ],
            passwordRules: [(v) => !!v || "Please Enter Password"],
            loading: false,
            incorrectAuth: false,
        };
    },
    methods: {
        login() {
            this.loading = true;
            const fd = {
                email: this.email,
                password: this.password1
            };
            this.store.loginUser(fd)
                .then(() => {
                    this.loading = false;
                    this.$router.push({
                        name: "dashboard",
                    })
                })
                .catch((err) => {
                    this.incorrectAuth = true;
                    this.loading = false;
                });
        },
    }

}
</script>
<style>
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}
</style>