<template>
    <div class="main">
        <div class=" d-flex justify-space-between pa-3">
            <h2  class="text-h6" >{{ project_name }}</h2>
        </div>
        <v-divider></v-divider>
    </div>
    <v-container class="pa-5">
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Feedbacks
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mt-2">
                        <v-row>
                            <v-col cols="4">
                                <v-select label="Select" density="compact" :items=feedbackTypes v-model="selectedFilter"></v-select>
                            </v-col>
                        </v-row>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-left font-weight-black">
                                        Feedback
                                    </th>
                                    <th class="text-left font-weight-black">

                                    </th>
                                    <th class="text-left font-weight-black">
                                        Email
                                    </th>
                                    <th class="text-left font-weight-black">
                                        Creation Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr v-for="item in filteredFeedbacks" :key="item.id">
                                    <td>
                                        <v-chip class="mr-2" :color="getChipColor(item.feedback_type)" size="small"
                                            dark>
                                            {{ item.feedback_type }}
                                        </v-chip>
                                    </td>
                                    <td>
                                        {{ item.text }}
                                    </td>
                                    <td>{{ item.email }}berred@gmail.com</td>
                                    <td>{{ new Date(item.created_at).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false // Use false for 24-hour format
                                    }) }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useDisplay } from "vuetify";
import { useAuth } from "@/store/auth.js";
import axios from "axios";

export default {
    setup() {
        const { display } = useDisplay();
        const store = useAuth();

        return { store }
    },

    components: {
    },

    data() {
        return {
            user_id: '',
            project_id: this.$route.params.project_id,
            project_name: '',
            feedbacks: '',
            selectedFilter: "", // Default to "All"
            feedbackTypes: [
                "All",
                "Bug Report",
                "Feature Request",
                "General Praise",
                "Complaint",
                "Other",
            ],
        };
    },

    methods: {
        getSentimentIcon(sentiment) {
            switch (sentiment) {
                case "Positive":
                    return "mdi-emoticon-happy";
                case "Negative":
                    return "mdi-emoticon-sad";
                case "Neutral":
                    return "mdi-emoticon-neutral";
                default:
                    return "mdi-help-circle"; // fallback icon
            }
        },
        getSentimentColor(sentiment) {
            switch (sentiment) {
                case "Positive":
                    return "green";
                case "Negative":
                    return "red";
                case "Neutral":
                    return "grey";
                default:
                    return "grey"; // fallback color
            }
        },
        getChipColor(feedbackType) {
            switch (feedbackType) {
                case "Bug Report":
                    return "red";
                case "Feature Request":
                    return "blue";
                case "General Praise":
                    return "green";
                case "Complaint":
                    return "orange";
                case "Other":
                    return "grey";
                default:
                    return "primary"; // fallback color
            }
        },
    },
    computed: {
        sortedFeedacks() {
            // Sort by `created_at` in descending order
            return [...this.feedbacks].sort((a, b) =>
                new Date(b.created_at) - new Date(a.created_at)
            );
        },

        filteredFeedbacks() {
            if (!this.selectedFilter || this.selectedFilter=='All') {
                return this.sortedFeedacks; // No filter applied, show all
            }
            var filtered = this.feedbacks.filter(
                (feedback) => feedback.feedback_type === this.selectedFilter
            );
            return filtered.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
        },
    },
    created() {
        this.user_id = this.store.getUserId

        axios
            .get(`/api/project/get/${this.project_id}/${this.user_id}`)
            .then((response) => {
                if (response.status == 200) {
                    this.project_name = response.data.project_name
                    this.feedbacks = response.data.feedbacks
                }
            })
            .catch((err) => { });
    }
};
</script>

<style scoped>
.modal {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.card-header {
    background-color: #f9f9f9;
}

.icon-votes {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.votes {
    font-size: 14px;
    font-weight: bold;
}
</style>