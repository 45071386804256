<template>
    <v-container>
        <v-img class="center" width="150" aspect-ratio="16/9" :src=logo></v-img>
        <v-row class="d-flex justify-center">
            <v-col :cols="$vuetify.display.smAndUp ? '6' : '12'">
                <v-form v-model="valid">
                    <h2 class="text-left mt-2 mb-2">Sign up</h2>
                    <v-divider />
                    <v-row class="d-flex mt-5">
                        <v-col cols="12">
                            <h3 class="mb-3">Email address</h3>
                            <v-text-field :rules="emailRules" v-model="email" required
                                placeholder="Enter your email address"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="mb-3">Password</h3>
                            <v-text-field :append-inner-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible1 ? 'text' : 'password'" outlined v-model="password1"
                                :rules="passwordRules" @click:append-inner="visible1 = !visible1"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <h3 class="mb-3">Verify Password</h3>
                            <v-text-field :append-inner-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                                :type="visible2 ? 'text' : 'password'" type="password" outlined v-model="password2"
                                :rules="confirmpassworRules" required
                                @click:append-inner="visible2 = !visible2"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn :disabled="!valid" :loading="loading" class="mt-5 text-white" color="indigo" size="large"
                        elevation="0" block dark @click="register">
                        <h3>Create an Acount</h3>
                    </v-btn>
                    <v-divider class="mt-5 mb-5" />
                    <div class="d-flex justify-space-between">
                        <p class="mt-1">Alerady have an Account ?</p>
                        <router-link class="text-decoration-none" :to="{
                            name: 'login',
                        }">
                            <v-btn variant="text">
                                <h4 class=" text-black text-decoration-underline">
                                    Sign in
                                </h4>
                            </v-btn></router-link>

                    </div>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import logo from "../assets/logo.png";
import { useAuth } from "@/store/auth.js";
import { useDisplay } from "vuetify";
import axios from "axios";


export default {
    name: 'Admin',

    setup() {
        const { display } = useDisplay();
        const store = useAuth();

        return { store }
    },

    components: {
    },

    data() {
        return {
            logo: logo,
            valid: false,
            username: '',
            email: '',
            password1: '',
            password2: '',
            visible1: false,
            visible2: false,
            Required: [
                (v) => !!v || "This field is required",
            ],
            emailRules: [
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Incorrect Email Adress'
            ],
            passwordRules: [(v) => !!v || "Please Enter Password"],
            confirmpassworRules: [
                (v) => !!v || "Please Enter Password",
                (v) => v == this.password1 || "Passwords do not match. Please try again.",
            ],
            loading: false,
        };
    },
    methods: {
        register() {
            this.loading = true
            const fd = {
                email: this.email,
                password: this.password1,
            };
            axios
                .post(`/api/user/signup`, fd)
                .then((response) => {
                    if (response.status == 200) {
                        this.store.user = response.data; // Set user data 
                        localStorage.setItem('user', JSON.stringify(response.data))
                        this.loading = false;
                        this.$router.push({
                            name: "dashboard",
                        })

                    }
                })
                .catch((error) => {
                    console.log(error)
                })

        },

    },

    mounted() {
    },


}
</script>
<style>
.container {
    padding-top: 5%;
    padding-left: 15%;
    padding-right: 15%;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
</style>