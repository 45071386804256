<template>
    <Header />
    <v-container class=" d-flex mt-5 ">
        <v-row>
            <v-col cols="12">

                <Analytics />
            </v-col>
            <v-col cols="12">
                <v-card class="mt-2" elevation="1" variant="outlined">
                    <div class="d-flex justify-space-between ma-2">
                        <v-card-title class="d-flex">
                            Demo Project
                        </v-card-title>
                        <div class="d-flex justify-center">
                            <v-btn class="text-capitalize" size="large" variant="outlined" color="blue-darken-4"
                                @click="this.openFeedbackModal()">
                                Send Feedback
                            </v-btn>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-card-text class="mt-2">
                        <v-table density="compact" height="200px">
                            <thead>
                                <tr>
                                    <th class="text-left font-weight-black">
                                        Feedback
                                    </th>
                                    <th class="text-left font-weight-black">

                                    </th>
                                    <th class="text-left font-weight-black">
                                        Sentiment
                                    </th>
                                    <th class="text-left font-weight-black">
                                        Email
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="">
                                <tr v-for="item in feedbacks" :key="item.id">
                                    <td>
                                        <v-chip class="mr-2" :color="getChipColor(item.feedback_type)" size="small"
                                            dark>
                                            {{ item.feedback_type }}
                                        </v-chip>
                                    </td>
                                    <td>
                                        {{ item.text }}
                                    </td>
                                    <td>
                                        <v-icon :color="getSentimentColor(item.sentiment)"
                                            :icon="getSentimentIcon(item.sentiment)"></v-icon>
                                    </td>
                                    <td>

                                    </td>
                                    <td>{{ item.email }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Header from '../components/Header.vue'
import { useDisplay } from "vuetify";

// Vercel Analytics
import { Analytics } from '@vercel/analytics/vue';

//Feedback Modal
import FeedbackModal from 'jsfeedback';

export default {
    setup() {
        const { display } = useDisplay();
    },

    components: {
        Header,
    },

    data() {
        return {
            feedbacks: [],
            demoApiKey: '',
            jsCode: `
import FeedbackModal from 'jsfeedback';

const feedbackModal = new FeedbackModal({
    submitUrl: 'your-project-submitUrl',
    apiKey: 'your-api-key',
    title: 'We Value Your Feedback!',
    description: 'Please let us know how we can improve.',
    onSuccess: (response) => console.log('Successful!',resonse),
    onError: (error) => console.log('Failed.',error),
});

// Open the modal on button click
document.getElementById('feedback-button').addEventListener('click', () => {
    feedbackModal.open();
});`,
            reactCode: `
import React from 'react';
import FeedbackModal from 'jsfeedback';

const App = () => {
    const feedbackModal = React.useRef(null);

    React.useEffect(() => {
        feedbackModal.current = new FeedbackModal({
            submitUrl: 'https://example.com/api/feedback',
            apiKey: 'your-api-key',
            title: 'We Value Your Feedback!',
            description: 'Please let us know how we can improve.',
            onSuccess: (response) => console.log('Successful!',resonse),
            onError: (error) => console.log('Failed.',error),
        });
    }, []);

    const openModal = () => {
        feedbackModal.current.open();
    };

    return (
        <div>
            <button onClick={openModal}>Give Feedback</button>
        </div>
    );
};

export default App;`

        };
    },

    methods: {
        getSentimentIcon(sentiment) {
            switch (sentiment) {
                case "Positive":
                    return "mdi-emoticon-happy";
                case "Negative":
                    return "mdi-emoticon-sad";
                case "Neutral":
                    return "mdi-emoticon-neutral";
                default:
                    return "mdi-help-circle"; // fallback icon
            }
        },
        getSentimentColor(sentiment) {
            switch (sentiment) {
                case "Positive":
                    return "green";
                case "Negative":
                    return "red";
                case "Neutral":
                    return "grey";
                default:
                    return "grey"; // fallback color
            }
        },
        getChipColor(feedbackType) {
            switch (feedbackType) {
                case "Bug Report":
                    return "red";
                case "Feature Request":
                    return "blue";
                case "General Praise":
                    return "green";
                case "Complaint":
                    return "orange";
                case "Other":
                    return "grey";
                default:
                    return "primary"; // fallback color
            }
        },

        openFeedbackModal() {
            const modal = new FeedbackModal({
                submitUrl: 'https://api.jsfeedback.com/feedback/new/aBkBFbiK',
                apiKey: this.demoApiKey,
                title: 'Demo Project Feedback',
                description: 'Please let us know how we can improve your experience',
                onSuccess: (data) => {
                    this.feedbacks.push(data)
                },
                onError: (error) => console.log('Error Occured', error),
            });
            modal.open()
        },
    },
    computed: {
        preactCode() {
            return Prism.highlight(this.reactCode, Prism.languages.jsx, "jsx")

        }
    },
    mounted() {
        this.demoApiKey = process.env.VUE_APP_APIKEY
        Prism.highlightAll();
    },
};
</script>
<style>
.container {
    padding: 10px;
}

.docs {
    width: 100%;
}


/* Table Block Specific */
.table-block table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.table-block th,
.table-block td {
    border: 1px solid #444444;
    /* Matches Prism's subtle line colors */
    padding: 8px 12px;
}

.table-block th {
    background-color: #2d2d2d;
    /* Slightly darker for headers */
    color: #dcdcdc;
    font-weight: bold;
}

.table-block td {
    background-color: #1e1e1e;
    /* Matches Prism Tomorrow background */
    color: #dcdcdc;
}

.table-block tr:nth-child(even) td {
    background-color: #2a2a2a;
    /* Alternate row color */
}
</style>